<template>
  <div class="limited" id="limited">
    <Nav/>
      <main id="main" role="main">
        <section id="lead">
            <h2><img src="@/assets/img/head_goshuin.svg" alt="御朱印"></h2>
            <ul class="column2-1">
              <li><figure><img src="@/assets/img/goshuin_6746.jpg" alt=""></figure><div class="box">
                <h3>熊野皇大神社の社文・社印の朱印</h3>
              </div></li>
              <li><figure><img src="@/assets/img/goshuin_6749.jpg" alt=""></figure><div class="box">
                <h3>県境印の朱印</h3>
              </div></li>
            </ul>
            <ul class="column2-1">
              <li><figure><img src="@/assets/img/goshuin_6733.jpg" alt=""></figure><div class="box">
                <h3>切り抜き朱印（八咫烏 黒）</h3>
                <p>日本初</p>
              </div></li>
              <li><figure><img src="@/assets/img/goshuin_6804.jpg" alt=""></figure><div class="box">
                <h3>切り抜き朱印（八咫烏 輝）</h3>
                <p>6月・12月限定</p>
              </div></li>
            </ul>
            <ul class="column2-1">
              <li><figure><img src="@/assets/img/goshuin_6810.jpg" alt=""></figure><div class="box">
                <h3>飛び出す しなの木朱印（しなの木神社）</h3>
                <p>日本初</p>
              </div></li>
              <li><figure><img src="@/assets/img/goshuin_6824.jpg" alt=""></figure><div class="box">
                <h3>飛び出す 八咫烏朱印</h3>
              </div></li>
            </ul>
            <ul class="column2-1">
              <li><figure><img src="@/assets/img/goshuin_6740.jpg" alt=""></figure><div class="box">
                <h3>特別朱印 花朱印</h3>
                <p>毎月1日・15日限定</p>
              </div></li>
              <li><figure><img src="@/assets/img/goshuin_torishuin.jpg" alt=""></figure><div class="box">
                <h3>特別朱印 烏牛王符</h3>
                <p>毎月1日・15日限定</p>
              </div></li>
            </ul>
            <ul class="column2-1">
              <li><figure><img src="@/assets/img/goshuin_6770.jpg" alt=""></figure><div class="box">
                <h3>四季の切り抜き朱印</h3>
              </div></li>
              <li><figure><img src="@/assets/img/goshuin_6755.jpg" alt=""></figure><div class="box">
                <h3>切り絵朱印（しなの木神社）</h3>
              </div></li>
            </ul>
          </section>
      </main>
    <Foot/>
  </div>
</template>

<script>
// @ is an alias to /src
import Foot from '@/components/Foot.vue'
import Nav from '@/components/Nav.vue'

export default {
  name: 'Limited',
  components: {
    Foot,
    Nav
  }
}
</script>

<style lang="scss" scoped>
  h3,
  p,a {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
  h3 {
    margin-bottom: 0;
  }
  figure {
    position: relative;
    background: url('~@/assets/img/goshuin_dammy.png') no-repeat center /contain;
    padding: 36%;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 73%;
    }
  }
</style>
